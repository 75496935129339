import {
	MasterJabatanListPage,
	MasterJabatanAddPage,
	MasterJabatanEditPage,
	MasterJabatanDetailPage,
} from "pages/SuperAdmin/MasterJabatan";
// import {MasterRoleListPage, MasterRoleAddPage, MasterRoleEditPage, MasterRoleDetailPage} from "pages/MasterRole";
import {
	MasterRumahSakitListPage,
	MasterRumahSakitAddPage,
	MasterRumahSakitEditPage,
	MasterRumahSakitDetailPage,
} from "pages/SuperAdmin/MasterHospital";
import {
	MasterDivisiListPage,
	MasterDivisiAddPage,
	MasterDivisiDetailPage,
	MasterDivisiEditPage,
} from "pages/SuperAdmin/MasterDivisi";
import {
	MasterUnitListPage,
	MasterUnitAddPage,
	MasterUnitDetailPage,
	MasterUnitEditPage,
} from "pages/SuperAdmin/MasterUnit";
import {StaffListPage, StaffAddPage, StaffDetailPage, StaffEditPage} from "pages/SuperAdmin/Staff";
import {
	MasterPoliklinikListPage,
	MasterPoliklinikAddPage,
	MasterPoliklinikEditPage,
	MasterPoliklinikDetailPage,
} from "pages/SuperAdmin/MasterPoliklinik";
import {
	MasterDiagnosaListPage,
	MasterDiagnosaAddPage,
	MasterDiagnosaEditPage,
	MasterDiagnosaDetailPage,
} from "pages/SuperAdmin/MasterDiagnosa";
import {
	MasterKategoriPemeriksaanListPage,
	MasterKategoriPemeriksaanAddPage,
	MasterKategoriPemeriksaanEditPage,
	MasterKategoriPemeriksaanDetailPage,
} from "pages/SuperAdmin/MasterKategoriPemeriksaan";
import {
	MasterPemeriksaanPenunjangAddPage,
	MasterPemeriksaanPenunjangDetailPage,
	MasterPemeriksaanPenunjangEditPage,
	MasterPemeriksaanPenunjangListPage,
} from "pages/SuperAdmin/MasterPemeriksaanPenunjang";
import {
	MasterHargaAdministrasiAddPage,
	MasterHargaAdministrasiDetailPage,
	MasterHargaAdministrasiEditPage,
	MasterHargaAdministrasiListPage,
} from "pages/SuperAdmin/MasterHargaAdministrasi";
import {
	MasterKonsultasiListPage,
	MasterKonsultasiAddPage,
	MasterKonsultasiEditPage,
	MasterKonsultasiDetailPage,
} from "pages/SuperAdmin/MasterKonsultasi";
import {DoctorListPage, DoctorDetailPage} from "pages/SuperAdmin/Doctor";
import {
	PendaftaranPasienListPage,
	PendaftaranPasienDetailPage,
	PendaftaranPasienEditPage,
} from "pages/SuperAdmin/PendaftaranPasien";
import {
	MedicineCategoryListPage,
	MedicineCategoryAddPage,
	MedicineCategoryEditPage,
	MedicineCategoryDetailPage,
} from "pages/SuperAdmin/MedicineCategory";
import {
	MedicineUnitListPage,
	MedicineUnitAddPage,
	MedicineUnitEditPage,
	MedicineUnitDetailPage,
} from "pages/SuperAdmin/MedicineUnit";
import {
	ActionCategoryListPage,
	ActionCategoryAddPage,
	ActionCategoryEditPage,
	ActionCategoryDetailPage,
} from "pages/SuperAdmin/ActionCategory";
import {ActionListPage, ActionAddPage, ActionEditPage, ActionDetailPage} from "pages/SuperAdmin/Action";
import {MedicineListPage, MedicineAddPage, MedicineEditPage, MedicineDetailPage} from "pages/SuperAdmin/Medicine";
import {
	MasterSediaanObatAdd,
	MasterSediaanObatDetail,
	MasterSediaanObatEdit,
	MasterSediaanObatList,
} from "pages/SuperAdmin/MasterSediaanObat";
import {AppointmentListPage, AppointmentDetailPage} from "pages/SuperAdmin/Appointment";
import {FormAddPage, FormListPage} from "pages/SuperAdmin/Settings/Form";
import {
	HargaPendaftaranObatAdd,
	HargaPendaftaranObatDetail,
	HargaPendaftaranObatEdit,
	HargaPendaftaranObatList,
} from "pages/SuperAdmin/HargaPendaftaranObat";
import {MasterLayananObatDetail, MasterLayananObatList} from "pages/SuperAdmin/LayananObat";
import {
	MasterVendorAddPage,
	MasterVendorDetailPage,
	MasterVendorEditPage,
	MasterVendorListPage,
	VendorPICAddPage,
	VendorPICDetailPage,
	VendorPICEditPage,
	VendorPICListPage,
} from "pages/SuperAdmin/MasterVendor";
import {MasterTagAdd, MasterTagDetail, MasterTagEdit, MasterTagList} from "pages/SuperAdmin/Tag";
import {
	MasterFileLabAddPage,
	MasterFileLabDetailPage,
	MasterFileLabEditPage,
	MasterFileLabListPage,
} from "pages/SuperAdmin/MasterFileLab";

export const superAdminRoutes = [
	{
		name: "admin-master-jabatan-list",
		path: "/master-jabatan",
		exact: true,
		isPrivate: true,
		component: MasterJabatanListPage,
	},
	{
		name: "admin-master-jabatan-tambah",
		path: "/master-jabatan/tambah",
		exact: true,
		isPrivate: true,
		component: MasterJabatanAddPage,
	},
	{
		name: "admin-master-jabatan-edit",
		path: "/master-jabatan/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterJabatanEditPage,
	},
	{
		name: "admin-master-jabatan-detail",
		path: "/master-jabatan/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterJabatanDetailPage,
	},
	{
		name: "admin-master-poliklinik-list",
		path: "/master-poliklinik",
		exact: true,
		isPrivate: true,
		component: MasterPoliklinikListPage,
	},
	{
		name: "admin-master-poliklinik-add",
		path: "/master-poliklinik/tambah",
		exact: true,
		isPrivate: true,
		component: MasterPoliklinikAddPage,
	},
	{
		name: "admin-master-poliklinik-edit",
		path: "/master-poliklinik/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterPoliklinikEditPage,
	},
	{
		name: "admin-master-poliklinik-details",
		path: "/master-poliklinik/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterPoliklinikDetailPage,
	},
	{
		name: "admin-master-diagnosa-list",
		path: "/master-diagnosa",
		exact: true,
		isPrivate: true,
		component: MasterDiagnosaListPage,
	},
	{
		name: "admin-master-diagnosa-add",
		path: "/master-diagnosa/tambah",
		exact: true,
		isPrivate: true,
		component: MasterDiagnosaAddPage,
	},
	{
		name: "admin-master-diagnosa-edit",
		path: "/master-diagnosa/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterDiagnosaEditPage,
	},
	{
		name: "admin-master-diagnosa-details",
		path: "/master-diagnosa/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterDiagnosaDetailPage,
	},
	{
		name: "admin-master-kategori-pemeriksaan-list",
		path: "/master-kategori-pemeriksaan",
		exact: true,
		isPrivate: true,
		component: MasterKategoriPemeriksaanListPage,
	},
	{
		name: "admin-master-kategori-pemeriksaan-add",
		path: "/master-kategori-pemeriksaan/tambah",
		exact: true,
		isPrivate: true,
		component: MasterKategoriPemeriksaanAddPage,
	},
	{
		name: "admin-master-kategori-pemeriksaan-edit",
		path: "/master-kategori-pemeriksaan/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterKategoriPemeriksaanEditPage,
	},
	{
		name: "admin-master-kategori-pemeriksaan-details",
		path: "/master-kategori-pemeriksaan/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterKategoriPemeriksaanDetailPage,
	},
	{
		name: "admin-master-pemeriksaan-penunjang-list",
		path: "/master-pemeriksaan-penunjang",
		exact: true,
		isPrivate: true,
		component: MasterPemeriksaanPenunjangListPage,
	},
	{
		name: "admin-master-pemeriksaan-penunjang-add",
		path: "/master-pemeriksaan-penunjang/tambah",
		exact: true,
		isPrivate: true,
		component: MasterPemeriksaanPenunjangAddPage,
	},
	{
		name: "admin-master-pemeriksaan-penunjang-edit",
		path: "/master-pemeriksaan-penunjang/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterPemeriksaanPenunjangEditPage,
	},
	{
		name: "admin-master-pemeriksaan-penunjang-details",
		path: "/master-pemeriksaan-penunjang/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterPemeriksaanPenunjangDetailPage,
	},
	{
		name: "admin-master-harga-administrasi-list",
		path: "/master-harga-administrasi",
		exact: true,
		isPrivate: true,
		component: MasterHargaAdministrasiListPage,
	},
	{
		name: "admin-master-harga-administrasi-add",
		path: "/master-harga-administrasi/tambah",
		exact: true,
		isPrivate: true,
		component: MasterHargaAdministrasiAddPage,
	},
	{
		name: "admin-master-harga-administrasi-edit",
		path: "/master-harga-administrasi/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterHargaAdministrasiEditPage,
	},
	{
		name: "admin-master-harga-administrasi-details",
		path: "/master-harga-administrasi/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterHargaAdministrasiDetailPage,
	},
	{
		name: "admin-master-konsultasi-list",
		path: "/master-konsultasi",
		exact: true,
		isPrivate: true,
		component: MasterKonsultasiListPage,
	},
	{
		name: "admin-master-konsultasi-add",
		path: "/master-konsultasi/tambah",
		exact: true,
		isPrivate: true,
		component: MasterKonsultasiAddPage,
	},
	{
		name: "admin-master-konsultasi-edit",
		path: "/master-konsultasi/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterKonsultasiEditPage,
	},
	{
		name: "admin-master-konsultasi-details",
		path: "/master-konsultasi/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterKonsultasiDetailPage,
	},
	// {
	// 	name: "admin-master-role",
	// 	path: "/master-role",
	// 	exact: true,
	// 	isPrivate: true,
	// 	component: MasterRoleListPage,
	// },
	// {
	// 	name: "admin-master-role",
	// 	path: "/master-role/tambah",
	// 	exact: true,
	// 	isPrivate: true,
	// 	component: MasterRoleAddPage,
	// },
	// {
	// 	name: "admin-master-role-edit",
	// 	path: "/master-role/edit/:id",
	// 	exact: true,
	// 	isPrivate: true,
	// 	component: MasterRoleEditPage,
	// },
	// {
	// 	name: "admin-master-role-detail",
	// 	path: "/master-role/show/:id",
	// 	exact: true,
	// 	isPrivate: true,
	// 	component: MasterRoleDetailPage,
	// },

	{
		name: "admin-master-vendor",
		path: "/master-vendor",
		exact: true,
		isPrivate: true,
		component: MasterVendorListPage,
	},
	{
		name: "admin-master-vendor-tambah",
		path: "/master-vendor/tambah",
		exact: true,
		isPrivate: true,
		component: MasterVendorAddPage,
	},
	{
		name: "admin-master-vendor-edit",
		path: "/master-vendor/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterVendorEditPage,
	},
	{
		name: "admin-master-vendor-show",
		path: "/master-vendor/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterVendorDetailPage,
	},
	{
		name: "admin-master-vendor-pic",
		path: "/master-vendor/:id/PIC",
		exact: true,
		isPrivate: true,
		component: VendorPICListPage,
	},
	{
		name: "admin-master-vendor-pic-tambah",
		path: "/master-vendor/:id/PIC/tambah",
		exact: true,
		isPrivate: true,
		component: VendorPICAddPage,
	},
	{
		name: "admin-master-vendor-pic-show",
		path: "/master-vendor/:id/PIC/show/:picId",
		exact: true,
		isPrivate: true,
		component: VendorPICDetailPage,
	},
	{
		name: "admin-master-vendor-pic-edit",
		path: "/master-vendor/:id/PIC/edit/:picId",
		exact: true,
		isPrivate: true,
		component: VendorPICEditPage,
	},
	{
		name: "admin-master-rumah-sakit",
		path: "/master-rumah-sakit",
		exact: true,
		isPrivate: true,
		component: MasterRumahSakitListPage,
	},
	{
		name: "admin-master-rumah-sakit-tambah",
		path: "/master-rumah-sakit/tambah",
		exact: true,
		isPrivate: true,
		component: MasterRumahSakitAddPage,
	},
	{
		name: "admin-master-rumah-sakit-edit",
		path: "/master-rumah-sakit/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterRumahSakitEditPage,
	},
	{
		name: "admin-master-rumah-sakit-detail",
		path: "/master-rumah-sakit/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterRumahSakitDetailPage,
	},
	{
		name: "admin-divisi",
		path: "/master-divisi",
		exact: true,
		isPrivate: true,
		component: MasterDivisiListPage,
	},
	{
		name: "admin-divisi-tambah",
		path: "/master-divisi/tambah",
		exact: true,
		isPrivate: true,
		component: MasterDivisiAddPage,
	},
	{
		name: "admin-master-divisi-edit",
		path: "/master-divisi/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterDivisiEditPage,
	},
	{
		name: "admin-master-divisi-detail",
		path: "/master-divisi/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterDivisiDetailPage,
	},
	{
		name: "admin-unit",
		path: "/master-unit",
		exact: true,
		isPrivate: true,
		component: MasterUnitListPage,
	},
	{
		name: "admin-unit-tambah",
		path: "/master-unit/tambah",
		exact: true,
		isPrivate: true,
		component: MasterUnitAddPage,
	},
	{
		name: "admin-master-unit-edit",
		path: "/master-unit/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterUnitEditPage,
	},
	{
		name: "admin-master-unit-detail",
		path: "/master-unit/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterUnitDetailPage,
	},
	{
		name: "staff",
		path: "/staff",
		exact: true,
		isPrivate: true,
		component: StaffListPage,
	},
	{
		name: "staff-tambah",
		path: "/staff/tambah",
		exact: true,
		isPrivate: true,
		component: StaffAddPage,
	},
	{
		name: "admin-staff-edit",
		path: "/staff/edit/:id",
		exact: true,
		isPrivate: true,
		component: StaffEditPage,
	},
	{
		name: "admin-staff-detail",
		path: "/staff/show/:id",
		exact: true,
		isPrivate: true,
		component: StaffDetailPage,
	},
	{
		name: "admin-doctor-list",
		path: "/doctor",
		exact: true,
		isPrivate: true,
		component: DoctorListPage,
	},
	{
		name: "admin-doctor-detail",
		path: "/doctor/show/:id",
		exact: true,
		isPrivate: true,
		component: DoctorDetailPage,
	},
	{
		name: "admin-patient-list",
		path: "/pendaftaran-pasien",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienListPage,
	},
	{
		name: "admin-patient-detail",
		path: "/pendaftaran-pasien/show/:id",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienDetailPage,
	},
	{
		name: "admin-patient-edit",
		path: "/pendaftaran-pasien/edit/:id",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienEditPage,
	},
	{
		name: "kategori-obat",
		path: "/kategori-obat",
		exact: true,
		isPrivate: true,
		component: MedicineCategoryListPage,
	},
	{
		name: "kategori-obat-tambah",
		path: "/kategori-obat/tambah",
		exact: true,
		isPrivate: true,
		component: MedicineCategoryAddPage,
	},
	{
		name: "kategori-obat-edit",
		path: "/kategori-obat/edit/:id",
		exact: true,
		isPrivate: true,
		component: MedicineCategoryEditPage,
	},
	{
		name: "kategori-obat-detail",
		path: "/kategori-obat/show/:id",
		exact: true,
		isPrivate: true,
		component: MedicineCategoryDetailPage,
	},
	{
		name: "unit-obat",
		path: "/unit-obat",
		exact: true,
		isPrivate: true,
		component: MedicineUnitListPage,
	},
	{
		name: "unit-obat-tambah",
		path: "/unit-obat/tambah",
		exact: true,
		isPrivate: true,
		component: MedicineUnitAddPage,
	},
	{
		name: "unit-obat-edit",
		path: "/unit-obat/edit/:id",
		exact: true,
		isPrivate: true,
		component: MedicineUnitEditPage,
	},
	{
		name: "unit-obat-detail",
		path: "/unit-obat/show/:id",
		exact: true,
		isPrivate: true,
		component: MedicineUnitDetailPage,
	},
	{
		name: "kategori-tindakan",
		path: "/kategori-tindakan",
		exact: true,
		isPrivate: true,
		component: ActionCategoryListPage,
	},
	{
		name: "kategori-tindakan-tambah",
		path: "/kategori-tindakan/tambah",
		exact: true,
		isPrivate: true,
		component: ActionCategoryAddPage,
	},
	{
		name: "kategori-tindakan-edit",
		path: "/kategori-tindakan/edit/:id",
		exact: true,
		isPrivate: true,
		component: ActionCategoryEditPage,
	},
	{
		name: "kategori-tindakan-detail",
		path: "/kategori-tindakan/show/:id",
		exact: true,
		isPrivate: true,
		component: ActionCategoryDetailPage,
	},
	{
		name: "tindakan",
		path: "/tindakan",
		exact: true,
		isPrivate: true,
		component: ActionListPage,
	},
	{
		name: "tindakan-tambah",
		path: "/tindakan/tambah",
		exact: true,
		isPrivate: true,
		component: ActionAddPage,
	},
	{
		name: "tindakan-edit",
		path: "/tindakan/edit/:id",
		exact: true,
		isPrivate: true,
		component: ActionEditPage,
	},
	{
		name: "tindakan-detail",
		path: "/tindakan/show/:id",
		exact: true,
		isPrivate: true,
		component: ActionDetailPage,
	},
	{
		name: "obat",
		path: "/obat",
		exact: true,
		isPrivate: true,
		component: MedicineListPage,
	},
	{
		name: "obat-tambah",
		path: "/obat/tambah",
		exact: true,
		isPrivate: true,
		component: MedicineAddPage,
	},
	{
		name: "obat-edit",
		path: "/obat/edit/:id",
		exact: true,
		isPrivate: true,
		component: MedicineEditPage,
	},
	{
		name: "obat-detail",
		path: "/obat/show/:id",
		exact: true,
		isPrivate: true,
		component: MedicineDetailPage,
	},
	{
		name: "sediaan-obat",
		path: "/sediaan-obat",
		exact: true,
		isPrivate: true,
		component: MasterSediaanObatList,
	},
	{
		name: "sediaan-obat-tambah",
		path: "/sediaan-obat/tambah",
		exact: true,
		isPrivate: true,
		component: MasterSediaanObatAdd,
	},
	{
		name: "sediaan-obat-detail",
		path: "/sediaan-obat/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterSediaanObatDetail,
	},
	{
		name: "sediaan-obat-edit",
		path: "/sediaan-obat/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterSediaanObatEdit,
	},
	{
		name: "harga-pendaftaran-obat",
		path: "/harga-pendaftaran-obat",
		exact: true,
		isPrivate: true,
		component: HargaPendaftaranObatList,
	},
	{
		name: "harga-pendaftaran-obat-tambah",
		path: "/harga-pendaftaran-obat/tambah",
		exact: true,
		isPrivate: true,
		component: HargaPendaftaranObatAdd,
	},
	{
		name: "harga-pendaftaran-obat-detail",
		path: "/harga-pendaftaran-obat/show/:id",
		exact: true,
		isPrivate: true,
		component: HargaPendaftaranObatDetail,
	},
	{
		name: "harga-pendaftaran-obat-edit",
		path: "/harga-pendaftaran-obat/edit/:id",
		exact: true,
		isPrivate: true,
		component: HargaPendaftaranObatEdit,
	},
	{
		name: "layanan-obat",
		path: "/layanan-obat",
		exact: true,
		isPrivate: true,
		component: MasterLayananObatList,
	},
	{
		name: "layanan-obat-tambah",
		path: "/layanan-obat/:action",
		exact: true,
		isPrivate: true,
		component: MasterLayananObatDetail,
	},
	{
		name: "layanan-obat-detail",
		path: "/layanan-obat/:action/:id",
		exact: true,
		isPrivate: true,
		component: MasterLayananObatDetail,
	},
	{
		name: "tag",
		path: "/tag",
		exact: true,
		isPrivate: true,
		component: MasterTagList,
	},
	{
		name: "tag-tambah",
		path: "/tag/tambah",
		exact: true,
		isPrivate: true,
		component: MasterTagAdd,
	},
	{
		name: "tag-edit",
		path: "/tag/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterTagEdit,
	},
	{
		name: "tag-detail",
		path: "/tag/show/:id",
		exact: true,
		isPrivate: true,
		component: MasterTagDetail,
	},
	{
		name: "appointment-list",
		path: "/appointment",
		exact: true,
		isPrivate: true,
		component: AppointmentListPage,
	},
	{
		name: "appointment-detail",
		path: "/appointment/show/:id",
		exact: true,
		isPrivate: true,
		component: AppointmentDetailPage,
	},
	{
		name: "form-list",
		path: "/form",
		exact: true,
		isPrivate: true,
		component: FormListPage,
	},
	{
		name: "form-add",
		path: "/form/:action",
		exact: true,
		isPrivate: true,
		component: FormAddPage,
	},
	{
		name: "form-edit",
		path: "/form/:action/:id",
		exact: true,
		isPrivate: true,
		component: FormAddPage,
	},
	{
		name: "admin-laboratoriun-master-file-lab",
		path: "/file-labs",
		exact: true,
		isPrivate: true,
		component: MasterFileLabListPage,
	},
	{
		name: "admin-laboratoriun-master-file-lab-add",
		path: "/file-labs/tambah",
		exact: true,
		isPrivate: true,
		component: MasterFileLabAddPage,
	},
	{
		name: "admin-laboratoriun-master-file-lab-edit",
		path: "/file-labs/edit/:id",
		exact: true,
		isPrivate: true,
		component: MasterFileLabEditPage,
	},
	{
		name: "admin-laboratoriun-master-file-lab-detail",
		path: "/file-labs/detail/:id",
		exact: true,
		isPrivate: true,
		component: MasterFileLabDetailPage,
	},
];
